<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Create Booking</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <BookingForm
        @submitted="createBooking"
        @locations="getLocations"
        @testees="getTestees"
        @projects="getProjects"
        :locations="locations"
        :testees="testees"
        :projects="projects"
      />
    </div>
  </div>
</template>

<script>
import BookingForm from '@/components/BookingForm'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'
import { useCreateBooking } from '@/composables/useCreateBooking'
import { useGetProjects } from '@/composables/useGetProjects'

export default {
  name: 'UserView',
  components: {
    BookingForm,
    BaseBreadcrumb
  },
  setup() {
    const {
      createBooking,
      locations,
      getLocations,
      testees,
      getTestees
    } = useCreateBooking({
      redirect: 'UpdateBooking'
    })
    const { projects, getProjects } = useGetProjects()
    const breadcrumbs = [
      { label: 'Bookings', to: 'requested-bookings' },
      { label: 'Create Booking' }
    ]

    return {
      locations,
      testees,
      createBooking,
      getLocations,
      getTestees,
      breadcrumbs,
      getProjects,
      projects
    }
  }
}
</script>
