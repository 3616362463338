<template>
  <Dialog
    header="Confirmation"
    v-model:visible="visible"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <div class="p-d-flex p-ai-center heading">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ message }}</span>
      </div>
      <p v-if="attention" style="color: red">
        <strong>{{ attention }}</strong>
      </p>
      <p v-if="note" class="p-mb-0">
        <em>{{ note }}</em>
      </p>
      <Textarea
        v-if="comment"
        v-model="comments"
        placeholder="Comments (optional)"
        rows="5"
        cols="30"
        class="p-mt-3"
      />
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        @click="visible = false"
        class="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        @click="$emit('confirm', comments)"
        class="p-button-text"
        autofocus
        :disabled="disable"
      />
    </template>
  </Dialog>
</template>

<script lang="ref">
import { ref, watch, watchEffect, defineComponent } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea';

export default defineComponent({
  components: { Dialog, Button, Textarea },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Are you sure you want to delete?'
    },
    attention: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: null
    },
    comment: {
      type: Boolean,
      default: false
    }
  },
  emits: ['confirm', 'display'],
  setup(props, { emit }) {
    const visible = ref(props.display)
    const comments = ref('')

    watch(visible, visible => {
      emit('display', visible)
    })

    watchEffect(() => {
      visible.value = props.display
    })

    return { visible, comments }
  }
})
</script>
